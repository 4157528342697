import React, { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import transition from "../../transition";
import "./Projects.css";
import BeyondImg from "../../Images/BeyondBoat.png";
import MailtoImg from "../../Images/MailtoGen.png";
import SaintDuJourImg from "../../Images/SaintDuJour.png";
import ZapiImg from "../../Images/zapi.png";
import Footer from "../Footer/Footer";
import Navbar from "../Nav/Nav";
import ArrowButton from "./ArrowButton";

const projects = [
  {
    title: "Zapi",
    description:
      "A modern and elegant website developed for Zapi, a renowned pizzeria in Paris. Built with a focus on user experience, this project integrates ZenChef for seamless restaurant management and Brevo for efficient customer communication. The result is a visually appealing and highly functional digital presence that enhances Zapi's online visibility.",
    image: ZapiImg,
    link: "https://www.zapi-paris.com/",
    technologies: ["React", "ZenChef", "Brevo"],
  },
  {
    title: "Beyond Boat",
    description:
      "A sleek and responsive website designed from a Figma template, developed using HTML, CSS, and JavaScript. This project demonstrates my ability to translate design concepts into a fully functional and visually compelling digital experience. With smooth animations and an intuitive layout, the website offers an engaging interface that highlights the essence of Beyond Boat's brand identity.",
    image: BeyondImg,
    link: "https://www.beyondboat.fr/",
    technologies: ["HTML", "CSS", "JavaScript"],
  },
  {
    title: "Mailto Generator",
    description:
      "A developer-friendly web tool designed to simplify the process of generating custom mailto links. Built with React and JavaScript, this application provides an intuitive interface for creating pre-filled email templates, saving time and reducing manual errors. With its clean UI and practical features, Mailto Generator is a valuable resource for developers and marketers alike.",
    image: MailtoImg,
    link: "https://mailtogen.tech/",
    technologies: ["React", "JavaScript"],
  },
  {
    title: "Saint Du Jour",
    description:
      "An interactive website dedicated to showcasing the saint of the day, leveraging real-time data from the Nominis API. Developed with React and Node.js, this project features a user-friendly interface and a well-structured backend to ensure seamless data retrieval and display. The platform aims to provide a meaningful and educational experience for users interested in religious history and traditions.",
    image: SaintDuJourImg,
    link: "https://saintdujour.org/",
    technologies: ["React", "NodeJS", "API"],
  },
];

const Project = () => {
  const [currentProject, setCurrentProject] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleNext = useCallback(() => {
    if (isTransitioning) return;
    setDirection(1);
    setIsTransitioning(true);
    setCurrentProject((prev) => (prev + 1) % projects.length);
  }, [isTransitioning]);

  const handlePrev = useCallback(() => {
    if (isTransitioning) return;
    setDirection(-1);
    setIsTransitioning(true);
    setCurrentProject((prev) => (prev === 0 ? projects.length - 1 : prev - 1));
  }, [isTransitioning]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "ArrowRight") handleNext();
      if (e.key === "ArrowLeft") handlePrev();
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [currentProject, handleNext, handlePrev, isTransitioning]);

  const slideVariants = {
    enter: (direction) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }),
  };

  const techBadgeVariants = {
    initial: { scale: 0, opacity: 0 },
    animate: (i) => ({
      scale: 1,
      opacity: 1,
      transition: {
        delay: i * 0.1,
      },
    }),
  };

  return (
    <>
      <Navbar uppercase={true} />
      <div className="project-container">
        <AnimatePresence
          mode="wait"
          onExitComplete={() => setIsTransitioning(false)}
        >
          <motion.div
            key={currentProject}
            className="project-info"
            custom={direction}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
          >
            <motion.div
              className="project-img"
              initial={{ clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)" }}
              animate={{ clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
            >
              <motion.img
                src={projects[currentProject].image}
                alt={projects[currentProject].title}
                initial={{ scale: 1.2 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
              />
            </motion.div>

            <motion.div
              className="project-description"
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <motion.h2
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                {projects[currentProject].title}
              </motion.h2>

              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                {projects[currentProject].description}
              </motion.p>

              <motion.div
                className="tech-stack"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              >
                {projects[currentProject].technologies.map((tech, index) => (
                  <motion.span
                    key={tech}
                    className="tech-badge"
                    custom={index}
                    variants={techBadgeVariants}
                    initial="initial"
                    animate="animate"
                  >
                    {tech}
                  </motion.span>
                ))}
              </motion.div>

              <motion.a
                href={projects[currentProject].link}
                target="_blank"
                rel="noopener noreferrer"
                className="project-url"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
                whileHover={{ scale: 1.05 }}
              >
                Visit the website
              </motion.a>
            </motion.div>
          </motion.div>
        </AnimatePresence>

        <div className="project-progress">
          <motion.div
            className="progress-bar"
            initial={{ scaleX: 0 }}
            animate={{ scaleX: (currentProject + 1) / projects.length }}
            transition={{ duration: 0.5 }}
          />
        </div>

        <div className="navigation">
          <ArrowButton
            direction="prev"
            onClick={handlePrev}
            disabled={isTransitioning}
          />
          <ArrowButton
            direction="next"
            onClick={handleNext}
            disabled={isTransitioning}
          />
        </div>

        <Footer />
      </div>
    </>
  );
};

export default transition(Project);

import React from "react";
import { motion } from "framer-motion";
import "./Projects.css";
const ArrowButton = ({ direction, onClick, disabled }) => {
  return (
    <motion.button
      className={`nav-button ${direction}`}
      onClick={onClick}
      disabled={disabled}
      whileTap={{ scale: 0.95 }}
    >
      <motion.div
        className="arrow-container"
        whileHover={{ x: direction === "prev" ? -5 : 5 }}
        transition={{ duration: 0.1 }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          {direction === "prev" ? (
            <path d="M19 12H5M12 19l-7-7 7-7" />
          ) : (
            <path d="M5 12h14M12 5l7 7-7 7" />
          )}
        </svg>
      </motion.div>
    </motion.button>
  );
};

export default ArrowButton;
